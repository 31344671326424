// app/loading/page.jsx
'use client';
export default function LoadingPage() {
    return (
        <div className="flex items-center justify-center min-h-[80vh] bg-gray-100">
            <div className="text-center flex justify-center items-center flex-col">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Loading...</h1>
                <p className="text-lg text-gray-600 mb-6">
                    Please wait while we fetch the information for you.
                </p>
                <div className="loader"></div>
                <style jsx>{`
                    .loader {
                        border: 8px solid rgba(0, 0, 0, 0.1);
                        border-left-color: #007bff;
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        animation: spin 1s linear infinite;
                    }

                    @keyframes spin {
                        to {
                            transform: rotate(360deg);
                        }
                    }
                `}</style>
            </div>
        </div>
    );
}